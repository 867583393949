<template>
<form @submit.prevent="">


    <slot v-if="isMobile">
        <div class="form-row">
            <v-text-field v-if="isMobile" class="mobile-app-input mobile-app-input-modal" label="عنوان ویجت" id="title" v-model="data.title"></v-text-field>
        </div>
        <div class="form-row">
            <v-select  v-model="data.chartType" class="profile-mobile-view-select mobile-view-select-modal fit-h" :items="chartTypes" item-text="name" item-value="type" label="دسته" outlined></v-select>
        </div>
        
        <div class="form-row">
            <v-select  v-model="data.chartFrame" class="profile-mobile-view-select mobile-view-select-modal fit-h" :items="chartFrames" item-text="name" item-value="type" label="بازه‌زمان" outlined></v-select>
        </div>

        <div class="form-row form-row-mobile-flex-modal" style="margin-top: 5px !important;margin-bottom: 2px;">
            <div class="col-12 col-md-4">
                <a href="" @click.prevent="openDiagram()" class="tgju-btn tgju-btn-lg tgju-btn-danger" style="max-width: 150px;margin-left: 10px;"><i class="uil uil-apps"></i> انتخاب شاخص</a>
                <div style="line-height: 1;padding-top: 15px;">
                    <label for="example-number-input5">شاخص فعلی : {{ data.symbol }}</label>
                </div>
            </div>
        </div>

    </slot>
    <slot v-if="!isMobile">
        <div class="form-row">
            <div class="col-12">
                <input type="text" id="title" placeholder="عنوان ویجت" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="data.title">
            </div>
        </div>

        <div class="form-row">
            <div class="col-12">
                <div class="widget-select w-100 m-bottom widget-select-lg">
                    <v-select2 v-model="data.chartType" :clearable="false" label="name" :reduce="(option) => option.type" :options="chartTypes">
                    </v-select2>
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="col-12">
                <div class="widget-select w-100 m-bottom widget-select-lg">
                    <v-select2 v-model="data.chartFrame" :clearable="false" label="name" :reduce="(option) => option.type" :options="chartFrames">
                    </v-select2>
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="col-4">
                <a href="" id="example-number-input5" @click.prevent="openDiagram()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-danger"><i class="uil uil-apps"></i> انتخاب شاخص</a>
            </div>
            <div class="col-8" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding-top: 12px;padding-right: 0 !important;">
                <label for="example-number-input5">شاخص فعلی : {{ data.symbol }}</label>
            </div>
        </div>
    </slot>


    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveSettings()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary"><i class="uil uil-save"></i> ذخیره</a>
        </div>
    </div>
</form>
</template>

<style lang="scss">
    #main-header {
        z-index: 100000000;
    }

    #bug-report-modal,
    .modal[role="dialog"] {
        z-index: 999999999;
    }

    #indicator-modal {
        z-index: 2147483647;
    }
</style>

<script>
// این کامپوننت مربوط به محتوای ویجت های تنظیمات (نمودار) است
import vSelect2 from 'vue-select'
Vue.component('v-select2', vSelect2);

export default {
    name: 'SettingsChart',
    components: {},
    props: ['data'],
    data: function () {
        return {
            isMobile:false,
            indicator: undefined,
            chartTypes: [{
                type: 'line',
                name: 'نمودار خطی',
            }, {
                type: 'area',
                name: 'نمودار حجمی',
            }, {
                type: 'candle',
                name: 'نمودار شمعی',
            }],
            chartFrames: [{
                type: 'daily',
                name: 'روزانه',
            }, {
                type: 'history',
                name: 'پیشینه',
            }],
        }
    },
    mounted() {
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // این متد جهت ارسال اطلاعات داخل مودال به کلاس یا واسط پرنت می باشد(همون کال باک های معروف)
        saveSettings() {
            if (this.indicator) {
                this.data.symbol = this.indicator.symbol;
                this.data.label = this.indicator.label;
            }

            let data = this.$helpers.unbindObject(this.data);
            this.$parent.saveSettings(data);
        },
        // این متد برای باز کردن پنجره انتخاب شاخص استفاده می شود
        openDiagram() {
            let callbackEvent = 'jQuery.hColumns' + new Date().getTime();
            localStorage.setItem('jQuery_hColumns_event', callbackEvent);

            this.$root.$on(callbackEvent, (data) => {
                this.$root.$off(callbackEvent);
                this.indicator = data;
            });

            $('#indicator-modal').show();
            $('#indicator-modal #columns2').html('');
            this.$helpers.diagrams_render();
            // if ( $('#indicator-modal #columns2').html() == '' ) {
            // 	this.$helpers.diagrams_render();
            // }
        },
    },
}
</script>
